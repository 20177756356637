
  const infographicPosts = [
    {
      id: 22,
      title: "External Innovation Processes",
      author: "Asier Gonzalez",
      date: "12 April 2025",
      image: "/assets/infography/externalinnovationprocessMain.png",
      modalImage: "/assets/infography/externalinnovationprocess.png",
      event: "external_innovation_process_blog_view",
      tags: "#Innovation #Processes #External",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/externalinnovationprocess",
      url:"https://asiergonzalez.es/infographic/externalinnovationprocess"
    },
    {
      id: 21,
      title: "Internal Innovation Processes",
      author: "Asier Gonzalez",
      date: "12 March 2025",
      image: "/assets/infography/internalinnovationprocessMain.png",
      modalImage: "/assets/infography/internalinnovationprocess.png",
      event: "internal_innovation_process_blog_view",
      tags: "#Innovation #Process #Internal",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/inetrnalinnovationprocess",
      url:"https://asiergonzalez.es/infographic/internalinnovationprocess"
    },
    {
      id: 20,
      title: "Business Angels",
      author: "Asier Gonzalez",
      date: "20 February 2025",
      image: "/assets/infography/businessangelsMain.png",
      modalImage: "/assets/infography/businessangels.png",
      event: "business_angels_blog_view",
      tags: "#Innovation #BusinessAngels",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/businessangels",
      url:"https://asiergonzalez.es/infographic/businessangels"
    },
    {
      id: 19,
      title: "Innovation Processes",
      author: "Asier Gonzalez",
      date: "12 January 2025",
      image: "/assets/infography/innovationprocessesMain.png",
      modalImage: "/assets/infography/innovationprocesses.png",
      event: "innovation_processes_blog_view",
      tags: "#Innovation #Processes",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/innovationprocesses",
      url:"https://asiergonzalez.es/infographic/innovationprocesses"
    },{
      id: 18,
      title: "Scaling MVPs",
      author: "Asier Gonzalez",
      date: "12 December 2024",
      image: "/assets/infography/mvpToScaleMain.png",
      modalImage: "/assets/infography/mvpToScale.png",
      event: "mvp_to_scale_blog_view",
      tags: "#StartUp #MVP #Scale",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/mvptoscale",
      url:"https://asiergonzalez.es/infographic/mvptoscale"
    },{
      id: 17,
      title: "Open Innovation Models",
      author: "Asier Gonzalez",
      date: "28 November 2024",
      image: "/assets/infography/openInnovationModelsMain.png",
      modalImage: "/assets/infography/openInnovationModels.png",
      event: "openinnovation_models_blog_view",
      tags: "#StartUp #Corporate #OpenInnovation",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/openinnovationmodels",
      url:"https://asiergonzalez.es/infographic/openinnovationmodels"
    },{
      id: 16,
      title: "Start-Up Investment",
      author: "Asier Gonzalez",
      date: "18 October 2024",
      image: "/assets/infography/startupinvestmentMain.png",
      modalImage: "/assets/infography/startupinvestment.png",
      event: "startup_investment_blog_view",
      tags: "#StartUp #Investment",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/startupinvestment",
      url:"https://asiergonzalez.es/infographic/startupinvestment"
    },{
      id: 15,
      title: "Start-Up, Scale-Up, SME & Corporation",
      author: "Asier Gonzalez",
      date: "25 September 2024",
      image: "/assets/infography/companiescomparisonMain.png",
      modalImage: "/assets/infography/companiescomparison.png",
      event: "companies_comparison_blog_view",
      tags: "#StartUp #ScaleUp #SME #Corp",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/companiescomparison",
      url:"https://asiergonzalez.es/infographic/companiescomparison"
    },
    {
      id: 14,
      title: "Insights from NEOTEC 2023",
      author: "Asier Gonzalez",
      date: "16 August 2024",
      image: "/assets/infography/neotec2023Main.png",
      modalImage: "/assets/infography/neotec2023.png",
      event: "neotec_2023_blog_view",
      tags: "#innovation #neotec2023",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/neotec2023",
      url:"https://asiergonzalez.es/infographic/neotec2023"
    },
    {
      id: 13,
      title: "Innovation Management Models Depending on Stage",
      author: "Asier Gonzalez",
      date: "10 July 2024",
      image: "/assets/infography/innovationmanagementMain.png",
      modalImage: "/assets/infography/innovationmanagement.png",
      event: "innovation_management_blog_view",
      tags: "#innovation #management",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/innovationmanagement",
      url:"https://asiergonzalez.es/infographic/innovationmanagement"
    },
    {
      id: 12,
      title: "Top 10 Common Mistakes Entrepreneurs Make",
      author: "Asier Gonzalez",
      date: "18 June 2024",
      image: "/assets/infography/toperrorsentrepreneurMain.png",
      modalImage: "/assets/infography/toperrorsentrepreneur.png",
      event: "top_errors_entrepreneur_blog_view",
      tags: "#innovation #entrepreneur",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/toperrorentrepreneur",
      url:"https://asiergonzalez.es/infographic/toperrorentrepreneur"
    },
    {
      id: 11,
      title: "Monitoring Value Chain · Not only Devices",
      author: "Asier Gonzalez",
      date: "28 May 2024",
      image: "/assets/infography/monitoringvaluechainMain.png",
      modalImage: "/assets/infography/monitoringvaluechain.png",
      event: "monitoring_value_chain_blog_view",
      tags: "#iot #monitoring #valuechain",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/monitoringvaluechain",
      url:"https://asiergonzalez.es/infographic/monitoringvaluechain"
    },
    {
      id: 10,
      title: "Madrid Innovation Ecosystem: Where Technology Meets Tradition",
      author: "Asier Gonzalez",
      date: "25 April 2024",
      image: "/assets/infography/innovatingMadridMain.png",
      modalImage: "/assets/infography/innovatingMadrid.png",
      event: "innovationMadrid_blog_view",
      tags: "#madrid #innovation #infographic",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/madridinnovation",
      url:"https://asiergonzalez.es/infographic/madridinnovation"
    },
    {
      id: 8,
      title: "Types of Innovation  - A journey through the Ten Types",
      author: "Asier Gonzalez",
      date: "01 Februray 2024",
      image: "/assets/infography/tentypesinnovationMain.png",
      modalImage: "/assets/infography/tentypesinnovation.png",
      event: "tentypesinnovation_blog_view",
      tags: "#tentypes #innovation #infographic",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/tentypesinnovation",
      url:"https://asiergonzalez.es/infographic/tentypesinnovation"
    },
    {
      id: 7,
      title: "Open Innovation  - No matter how big a company is",
      author: "Asier Gonzalez",
      date: "12 January 2024",
      image: "/assets/infography/openInnovationMain.png",
      modalImage: "/assets/infography/openInnovation.png",
      event: "openinnovation_blog_view",
      tags: "#openinnovation #infographic",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/openinnovation",
      url:"https://asiergonzalez.es/infographic/openinnovation"
    },
    {
      id: 5,
      title: "Building MVP - First Product Version · Keep it simple",
      author: "Asier Gonzalez",
      date: "30 November 2023",
      image: "/assets/infography/buildingMvpMain.png",
      modalImage: "/assets/infography/buildingmvp.png",
      event: "buildingmvp_blog_view",
      tags: "#mvp #innovation #infographic",
      modal: "mymodal",
      width: "800px",
      path: "/infographic/buildingmvp",
      url:"https://asiergonzalez.es/infographic/buildingmvp"
    },
    {
      id: 3,
      title: "Key reasons behind my POCs and MVPs failing",
      author: "Asier Gonzalez",
      date: "28 September 2023",
      image: "/assets/carousel/UnlockIoTSuccess.png",
      modalImage: "/assets/carousel/UnlockIoTSuccess.png",
      pdfFile: "/assets/carousel/unlockIoTSuccess.pdf",
      event: "unlockiotsuccess_blog_view",
      tags: "#iot #success #POCs #MVPs #carousel",
      modal: "myvideomodal",
      width: "600px",
      path: "/infographic/unlockiotsuccess",
      url:"https://asiergonzalez.es/infographic/unlockiotsuccess"

    },
    {
      id: 2,
      title: "Understanding Cellular IoT · From 1G to promising 5G",
      author: "Asier Gonzalez",
      date: "11 September 2023",
      image: "/assets/carousel/understandingCellularIoT.png",
      modalImage: "/assets/carousel/understandingCellularIoT.png",
      pdfFile: "/assets/carousel/understandingCellularIoT.pdf",
      event: "understandingcellulariot_blog_view",
      tags: "#cellular #iot #carousel",
      modal: "myvideomodal",
      width: "800px",
      path: "/infographic/understandingcellulariot",
      url:"https://asiergonzalez.es/infographic/understandingcellulariot"

    }
  ];

  export default infographicPosts;
