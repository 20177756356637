const books = [
  {
    id: 2,
    title: "Great Leaders ",
    subtitle: "Simon Sinek",
    image: "/assets/talks/greatleader.png",
    event: "greatleader_talks_view",
    tags: "#talks #greatleader",
    date: "2010",
    path:"/talks/greatleader",
    url:"https://asiergonzalez.es/talks/greatleader",
    talkpath:"https://www.youtube.com/embed/qp0HIF3SfI4"
  },{
    id: 1,
    title: "Open Innovation",
    subtitle: "Henry Chesbrough",
    image: "/assets/talks/openinnovation.png",
    event: "openinnovation_talks_view",
    tags: "#talks #openinnovation",
    date: "2017",
    path:"/talks/openinnovation",
    url:"https://asiergonzalez.es/talks/openinnovation",
    talkpath:"https://www.youtube.com/embed/2UDBaDtwXfI"
  }
];

export default books;
