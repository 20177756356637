import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import SubscribeForm from "../mailchimp/SubscribeForm";
import PdfViewer from "../pdf/PdfViewer";
import { useLocation } from 'react-router-dom';
import infographicPosts from "./InfographicPost";
import ShareableLink from "../ShareableLink";

Modal.setAppElement("#root");

const Infographic = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const infographicsPerPage = 4; // Adjust as needed

  useEffect(() => {
    const currentPath = location.pathname;

    // Call the modal toggling function based on the currentPath
    toogleModalBasedOnPath(currentPath);
  }, [location.pathname]);

  const toogleModalBasedOnPath = (currentPath) => {
    // Implement your logic to determine which modal to toggle based on the currentPath
    const post = infographicPosts.find(post => post.path === currentPath);

    if (post) {
      toogleModal(post);
    }
  };

  const toogleModal = (post) => {
    setIsOpen(isOpen === post.id ? false : post.id);
    window.dataLayer.push({ 'event': post.event});

  };


  // Sort infographics posts by date in descending order
  const sortedInfographicPosts = infographicPosts.sort((a, b) => b.date - a.date);

  const filteredPosts = sortedInfographicPosts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //post.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
    post.author.toLowerCase().includes(searchQuery.toLowerCase()) ||
    post.tags.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const indexOfLastPost = currentPage * infographicsPerPage;
  const indexOfFirstPost = indexOfLastPost - infographicsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching

  };
  return (
    <>
      <div className="container">
        <div className="tokyo_tm_news">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <span>INFOGRAPHIC</span>
                <h3>Infographic</h3>
              </div>
              <div className="subscribe">
                <SubscribeForm />
              </div>
            </div>
          </div>
          <div className="line">
            <div className="pagination">
              {Array.from({ length: Math.ceil(filteredPosts.length / infographicsPerPage) }, (_, i) => i + 1).map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={number === currentPage ? "active" : ""}
                >
                  {number}
                </button>
              ))}
            </div>
            <input
              style={{ outline: "none" }}
              type="text"
              placeholder="Search posts"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>

          <ul>
            {currentPosts.map((post) => (
              <li className="no" key={post.id} data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                <div className="list_inner">
                  <div className="image" onClick={() => toogleModal(post)}>
                    <img src="/assets/img/thumbs/1-1.jpg" alt="thumb" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${post.image})`
                      }}
                    >
                    </div>
                  </div>
                  <div className="details">
                    <div className="extra">
                      <p className="date">
                        By <a href="#">{post.author}</a>
                        <span>{post.date}</span>
                      </p>
                    </div>
                    <h3 className="title" onClick={() => toogleModal(post)}>
                      {post.title}
                    </h3>
                    <div>
                      <span>{post.tags}</span>
                    </div>
                    <div className="tokyo_tm_read_more">
                      <a onClick={() => toogleModal(post)}>
                        <span>Read More</span>
                      </a>     
                    </div>
                    <ShareableLink url={post.url} />
                  </div>
                  <Modal
                    isOpen={isOpen === post.id}
                    onRequestClose={() => toogleModal(post)}
                    contentLabel="My dialog"
                    className={post.modal}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}
                  >
                    <popup>
                    <div className="tokyo_tm_modalbox_news">
                      <button className="close-modal" onClick={() => toogleModal(post)}>
                        <img src="/assets/img/svg/cancel.svg" alt="close icon" />
                      </button>
                      <div className="box_inner" style={{ maxWidth: `${post.width}` }}>
                        {post.pdfFile ? (
                        <div className="box_pdf">
                          <PdfViewer PDFfile={post.pdfFile} />
                        </div>
                        ) : (
                          <div className="box_inner">
                            {post.modalImage && (
                              <img src={post.modalImage} alt="thumb" />
                            )}
                            {post.modalContent && (
                              <div className="description_wrap scrollable">
                                <div className="details">
                                  <div className="extra">
                                    <p className="date">
                                      By <a href="#">Asier González Gómez</a>
                                      <span>{post.date}</span>
                                    </p>
                                  </div>
                                  <h3 className="title">
                                    {post.title}
                                  </h3>
                                </div>
                                <div className="main_content ">
                                  <div className="descriptions">
                                    <div dangerouslySetInnerHTML={{ __html: post.modalContent }} />
                                    <div className="news_share"><span>{post.tags} </span></div>
                                    <ShareableLink url={post.url} />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    </popup>
                  </Modal>
                </div>
              </li>
            ))}
          </ul>
          <div className="pagination">
              {Array.from({ length: Math.ceil(filteredPosts.length / infographicsPerPage) }, (_, i) => i + 1).map((number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  className={number === currentPage ? "active" : ""}
                >
                  {number}
                </button>
              ))}
            </div>
        </div>
      </div>
    </>
  );
};
export default Infographic;
