import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery } from "react-photoswipe-gallery";
import { useLocation } from 'react-router-dom';
import Modal from "react-modal";
import talks from "./Talks";
import books from "./Books";
import quotes from "./Quotes";

import ShareableLink from "../ShareableLink";

Modal.setAppElement("#root");

const ContentHub = () => {

  const location = useLocation();
  const [isQuoteOpen, setIsQuoteOpen] = useState(false);
  const [isBookOpen, setIsBookOpen] = useState(false);
  const [isTalkOpen, setIsTalkOpen] = useState(false);
  const [currentQuotePage, setcurrentQuotePage] = useState(1);
  const [currentBookPage, setcurrentBookPage] = useState(1);
  const [currentTalkPage, setcurrentTalkPage] = useState(1);
  const [searchQuoteQuery, setsearchQuoteQuery] = useState("");
  const [searchBookQuery, setsearchBookQuery] = useState("");
  const [searchTalkQuery, setsearchTalkQuery] = useState("");
  const quotesPerPage = 4; // Adjust as needed
  const booksPerPage = 4;
  const talksPerPage = 4;

  useEffect(() => {
    const currentPath = location.pathname;
    // Call the modal toggling function based on the currentPath
    toogleModalBasedOnPath(currentPath);
  }, [location.pathname]);

  const toogleModalBasedOnPath = (currentPath) => {
    // Implement your logic to determine which modal to toggle based on the currentPath
    const quote = quotes.find(quote => quote.path === currentPath);
    const book = books.find(book => book.path === currentPath);
    const talk = talks.find(talk => talk.path === currentPath);

    if (quote) {
      toogleModalQuote(quote);
    }
    if (book) {
      toogleModalBook(book);
    }
    if (talk) {
      toogleModalTalk(talk);
    }

  };

  const toogleModalQuote = (quote) => {
    setIsQuoteOpen(isQuoteOpen === quote.id ? false : quote.id);
    window.dataLayer.push({ 'event': quote.event });
  };
  const toogleModalBook = (book) => {
    setIsBookOpen(isBookOpen === book.id ? false : book.id);
    window.dataLayer.push({ 'event': book.event });
  };
  const toogleModalTalk = (talk) => {
    setIsTalkOpen(isTalkOpen === talk.id ? false : talk.id);
    window.dataLayer.push({ 'event': talk.event });
  };

  function clickOnQuotes() {
    window.dataLayer.push({ 'event': 'quotes_view' });
  }

  function clickOnBooks() {
    window.dataLayer.push({ 'event': 'books_view' });
  }

  function clickOnTalks() {
    window.dataLayer.push({ 'event': 'media_view' });
  }
  // Sort proejcts by date in descending order
  const sortedQuotes = quotes.sort((a, b) => b.date - a.date);
  const sortedBooks = books.sort((a, b) => b.date - a.date);
  const sortedTalks = talks.sort((a, b) => b.date - a.date);

  const filteredQuotes = sortedQuotes.filter(quote =>
    quote.tags.toLowerCase().includes(searchQuoteQuery.toLowerCase())
  );
  const filteredBooks = sortedBooks.filter(book =>
    book.title.toLowerCase().includes(searchBookQuery.toLowerCase()) ||
    book.tags.toLowerCase().includes(searchBookQuery.toLowerCase())
  );
  const filteredTalks = sortedTalks.filter(talk =>
    talk.title.toLowerCase().includes(searchTalkQuery.toLowerCase()) ||
    talk.tags.toLowerCase().includes(searchTalkQuery.toLowerCase())
  );

  const indexOfLastQuote = currentQuotePage * quotesPerPage;
  const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
  const currentQuotes = filteredQuotes.slice(indexOfFirstQuote, indexOfLastQuote);
  const quotepaginate = (quotepageNumber) => {
    setcurrentQuotePage(quotepageNumber);
  };

  const indexOfLastBook = currentBookPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentBooks = filteredBooks.slice(indexOfFirstBook, indexOfLastBook);
  const bookpaginate = (bookpageNumber) => {
    setcurrentBookPage(bookpageNumber);
  };


  const indexOfLastTalk = currentTalkPage * talksPerPage;
  const indexOfFirstTalk = indexOfLastTalk - talksPerPage;
  const currentTalks = filteredTalks.slice(indexOfFirstTalk, indexOfLastTalk);
  const talkpaginate = (pageNumber) => {
    setcurrentTalkPage(pageNumber);
  };


  const handleQuoteSearch = (equote) => {
    setsearchQuoteQuery(equote.target.value);
    setcurrentQuotePage(1); // Reset to first page when searching
  };

  const handleBookSearch = (ebook) => {
    setsearchBookQuery(ebook.target.value);
    setcurrentBookPage(1); // Reset to first page when searching
  };

  const handleTalkSearch = (etalk) => {
    setsearchTalkQuery(etalk.target.value);
    setcurrentTalkPage(1); // Reset to first page when searching
  };

  return (
    <>
      <Gallery>
        <div className="container">
          <div className="tokyo_tm_portfolio">
            <div className="tokyo_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Content Hub</span>
                  <h3 style={{ color: "#00ca77" }}>Content Hub</h3>
                </div>
              </div>
            </div>
            <div className="portfolio_filter">
              <Tabs>
                <TabList>
                  <Tab onClick={clickOnQuotes}>Quotes</Tab>
                  <Tab onClick={clickOnBooks}>Books</Tab>
                  <Tab onClick={clickOnTalks}>Talks</Tab>
                </TabList>
                <div className="list_wrapper">
                  {/* PROJECTS */}
                  <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredQuotes.length / quotesPerPage) }, (_, i) => i + 1).map((number) => (
                          <button
                            key={number}
                            onClick={() => quotepaginate(number)}
                            className={number === currentQuotePage ? "active" : ""}
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search quotes"
                        value={searchQuoteQuery}
                        onChange={handleQuoteSearch}
                      />
                    </div>
                    <div className="portfolio_list">
                      <ul>
                        {currentQuotes.map((quote) => (
                          <li className="no" key={quote.id} data-aos="fade-right" data-aos-duration="1200">
                            <div className="inner">
                              <div className="entry tokyo_tm_portfolio_animation_wrap">
                                <img
                                  src={quote.image}
                                  alt="Details"
                                  data-tip
                                  data-for="detail"
                                  onClick={() => toogleModalQuote(quote)}
                                />
                              </div>
                              <div>
                                <h5>{quote.author}</h5>
                                <p><strong>{quote.where}</strong></p>
                                <p>{quote.tags}</p>
                              </div>
                              <ShareableLink url={quote.url} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="pagination">
                      {Array.from({ length: Math.ceil(filteredQuotes.length / quotesPerPage) }, (_, i) => i + 1).map((number) => (
                        <button
                          key={number}
                          onClick={() => quotepaginate(number)}
                          className={number === currentQuotePage ? "active" : ""}
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                  </TabPanel>
                  {/* CONFERENCES */}
                  <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredBooks.length / booksPerPage) }, (_, i) => i + 1).map((booknumber) => (
                          <button
                            key={booknumber}
                            onClick={() => bookpaginate(booknumber)}
                            className={booknumber === currentBookPage ? "active" : ""}
                          >
                            {booknumber}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search books"
                        value={searchBookQuery}
                        onChange={handleBookSearch}
                      />
                    </div>
                    <ul className="portfolio_list">
                      {currentBooks.map((book) => (
                        <li className="no" key={book.id} data-aos="fade-right" data-aos-duration="1200">
                          <div className="inner">
                            <div className="entry tokyo_tm_portfolio_animation_wrap">
                              <img
                                src={book.image}
                                alt="Youtube"
                                data-tip
                                data-for="youtube"
                                onClick={() => toogleModalBook(book)}
                              />
                            </div>
                            <div>
                              <h5>{book.title} </h5>
                              <span><strong>{book.author}</strong></span><br />
                              <span>{book.tags}</span>
                            </div>
                            <ShareableLink url={book.url} />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredBooks.length / booksPerPage) }, (_, i) => i + 1).map((booknumber) => (
                          <button
                            key={booknumber}
                            onClick={() => bookpaginate(booknumber)}
                            className={booknumber === currentBookPage ? "active" : ""}
                          >
                            {booknumber}
                          </button>
                        ))}
                      </div>
                  </TabPanel>
                   {/* VIDEOS */}
                   <TabPanel>
                    <div className="line">
                      <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredTalks.length / talksPerPage) }, (_, i) => i + 1).map((talknumber) => (
                          <button
                            key={talknumber}
                            onClick={() => talkpaginate(talknumber)}
                            className={talknumber === currentTalkPage ? "active" : ""}
                          >
                            {talknumber}
                          </button>
                        ))}
                      </div>
                      <input
                        style={{ outline: "none" }}
                        type="text"
                        placeholder="Search talks"
                        value={searchTalkQuery}
                        onChange={handleTalkSearch}
                      />
                    </div>
                    <ul className="portfolio_list">
                      {currentTalks.map((talk) => (
                        <li className="no" key={talk.id} data-aos="fade-right" data-aos-duration="1200">
                          <div className="inner">
                            <div className="entry tokyo_tm_portfolio_animation_wrap">
                              <img
                                src={talk.image}
                                alt="Youtube"
                                data-tip
                                data-for="youtube"
                                onClick={() => toogleModalTalk(talk)}
                              />
                            </div>
                            <div>
                              <h5>{talk.title} </h5>
                              <span>{talk.subtitle}</span><br />
                              <span>{talk.tags}</span>
                            </div>
                            <ShareableLink url={talk.url} />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div className="pagination">
                        {Array.from({ length: Math.ceil(filteredTalks.length / talksPerPage) }, (_, i) => i + 1).map((talknumber) => (
                          <button
                            key={talknumber}
                            onClick={() => talkpaginate(talknumber)}
                            className={talknumber === currentTalkPage ? "active" : ""}
                          >
                            {talknumber}
                          </button>
                        ))}
                      </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </Gallery>
      {quotes.map((quote) => (
        <Modal
          isOpen={isQuoteOpen === quote.id}
          onRequestClose={() => toogleModalQuote(quote)}
          contentLabel="My dialog"
          className="mymodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
            <button className="close-modal" onClick={() => toogleModalQuote(quote)}>
              <img src="/assets/img/svg/cancel.svg" alt="close icon" />
            </button>
            <div className="box_inner">
              <div className="description_wrap scrollable">
                <div className="portfolio_main_title">
                  <h5>{quote.author}</h5>
                  <p><strong>{quote.where}</strong></p>
                </div>
                <div className="details">
                  <div className="textbox">
                    <div dangerouslySetInnerHTML={{ __html: quote.content }} />
                  </div>
                </div>
              </div>
            </div>
            {/* END BOX INNER */}
          </div>
        </Modal>
      ))}
      {books.map((book) => (
        <Modal
        isOpen={isBookOpen === book.id}
        onRequestClose={() => toogleModalBook(book)}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={() => toogleModalBook(book)}>
            <img src="/assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="portfolio_main_title">
                <h5>{book.title}</h5>
                <p><strong>{book.author}</strong></p>
              </div>
              <div className="details">
                <div className="textbox">
                  <div dangerouslySetInnerHTML={{ __html: book.content }} />
                </div>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
      </Modal>
      ))}
      {talks.map((talk) => (
        <Modal
          isOpen={isTalkOpen === talk.id}
          onRequestClose={() => toogleModalTalk(talk)}
          contentLabel="My dialog"
          className="myvideomodal"
          overlayClassName="myoverlay"
          closeTimeoutMS={500}
        >
          <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox" >
            <button className="close-modal" onClick={() => toogleModalTalk(talk)}>
              <img src="assets/img/svg/cancel.svg" alt="close icon" />
            </button>
            <iframe
              class="embedded_video"
              src={`${talk.talkpath}`} 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
              >
            </iframe>
          </div>
        </Modal>
      ))}
    </>
  );
};

export default ContentHub;
