const books = [
  {
    id: 1,
    author: "Amazon",
    title: "Working Backwards",
    category: "Innovation",
    image: "/assets/books/workingbackwards.png",
    content: `
    <p>If I had to recommend just one book on how to innovate with real impact, <strong>Working Backwards</strong> would be at the top of my list. It’s not just an inside look at Amazon but a practical guide on how to truly put the customer at the center of innovation.</p><br>

<p>What fascinates me the most is its radically different approach compared to traditional models like the Business Model Canvas or endless PowerPoint presentations. Instead, Amazon developed a simple yet incredibly effective technique: the <strong>Press Release & FAQs</strong>. Rather than starting with a product or a business idea, they begin by writing the press release for the ideal launch and the frequently asked questions a customer would have.</p><br>

<p><strong>Why is this method so powerful?</strong></p><br>

<ul>
<li>It forces you to think about the customer first, not the company.</li>
<li>It encourages clarity and eliminates unnecessary corporate jargon.</li>
<li>It removes the noise and helps focus on what truly matters.</li>
</ul><br>

<p>Less is more. In a world where innovation often drowns in bureaucracy, <strong>Working Backwards</strong> is a reminder that simplicity and customer obsession are the true drivers of change.</p>

    `,
    event: "workingbackwards_book_view",
    tags: "#workingbackwards #book #innovation",
    path:"/contenthub/book/workingbackwards",
    url:"https://asiergonzalez.es/contenthub/book/workingbackwards",
  }
];

export default books;
